@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
   color: #003366;
   font-family: "Inter", sans-serif;
   -webkit-tap-highlight-color: transparent;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

html {
   /* transform: scale(1.2);
   transform-origin: top left; */
}

.bg-dark {
   background-color: #003366 !important;
}

.pointer-events-none {
   pointer-events: none !important;
}

.markdown-styles {
}

.markdown-styles p {
   /* font-size: 16px; */
   /* font-weight: bold; */
   margin-bottom: 12px;
}

.markdown-styles h1 {
   font-size: 48px;
   font-weight: bold;
   margin-bottom: 18px;
   margin-top: 16px;
}

.markdown-styles h2 {
   font-size: 40px;
   font-weight: bold;
   margin-bottom: 16px;
   margin-top: 16px;
}

.markdown-styles h3 {
   font-size: 40px;
   font-weight: bold;
   margin-bottom: 0;
   margin-top: 32px;
}
.text-content > h3:first-child,
.text-content > h4:first-child {
   margin-top: 0;
   margin-bottom: 12px;
}

.markdown-styles h4 {
   font-size: 36px;

   font-weight: bold;
   margin-bottom: 12px;
   margin-top: 16px;
}

.markdown-styles h5 {
   font-size: 14px;
   font-weight: bold;
   margin-top: 16px;
}

.bubble {
   position: relative;
   background-color: #fff;
   box-shadow: 0px 16px 32px rgba(0, 51, 102, 0.08);
}

.bubble::before,
.bubble::after {
   bottom: -0.1rem;
   content: "";
   height: 1rem;
   position: absolute;
}
.bubble::before {
   border-bottom-right-radius: 0.8rem 0.7rem;
   border-left: 1rem solid #fff;
   left: -0.35rem;
   transform: translate(0, -0.1rem);
}
.bubble::after {
   background-color: #f4fbfd;
   border-bottom-right-radius: 0.5rem;
   left: 20px;
   transform: translate(-30px, -2px);
   width: 10px;
}

/* .bubble::after {
   bottom: -0.13rem;
   content: "";
   height: 1.3rem;
   position: absolute;
   background-color: #f4fbfd;
   border-bottom-right-radius: 0.7rem;
   left: 26px;
   transform: translate(-38px, -3px);
   width: 13px;
   margin-bottom: 16px;
} */
